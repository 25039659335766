<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
<style lang="scss">
@import "./assets/css/common.scss";
html {
  background-color: #ffffff;
  color: #323233;
  font-size: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.content p {
  font-size: 14px;
  line-height: 24px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>