import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import { Icon, Loading, Button, Toast } from 'vant';
import dayjs from "dayjs"
import 'vant/lib/index.css';
import 'amfe-flexible'

axios.defaults.baseURL = 'https://huilyu.cn/index.php'
axios.defaults.timeout = 2000

Vue.prototype.$axios = axios
Vue.config.productionTip = false

let baseURL = 'https://huilyu.cn'
Vue.prototype.$baseURL = baseURL

const filters = {
  timeFormater(value, str = 'YYYY年MM月DD日 HH:mm:ss') {
    let values = value * 1000
    return dayjs(values).format(str)
  }
}

for (let key in filters) {
  Vue.filter(key, filters[key]);
}

Vue.use(Icon);
Vue.use(Loading);
Vue.use(Button);
Vue.use(Toast);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
