import Vue from 'vue'
import VueRouter from 'vue-router'
const Index = () => import('@/views/Home/index')
const List = () => import('@/views/List/index')
const Details = () => import('@/views/Details/index')
const Listimg = () => import('@/views/Listimg/index')
const ContactUs = () => import('@/views/ContactUs/index')
const EnterpriseHonor = () => import('@/views/EnterpriseHonor/index')
const TalentRecruitment = () => import('@/views/TalentRecruitment/index')
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes: [
    { path: '/', component: Index, name: "Index", },
    { path: '/List', component: List, name: "List", },
    { path: '/Details', component: Details, name: "Details", },
    { path: '/Listimg', component: Listimg, name: "Listimg", },
    { path: '/ContactUs', component: ContactUs, name: "ContactUs", },//联系方式完成
    { path: '/EnterpriseHonor', component: EnterpriseHonor, name: "EnterpriseHonor", },//企业荣誉
    { path: '/TalentRecruitment', component: TalentRecruitment, name: "TalentRecruitment", },//人才招聘
  ]
})
export default router